import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { auth, fb, firestore } from '../firebase';
import { useStore } from '../store';
import App from './App';
import Loader from './components/Loader';
import ReferralRedirect from './components/ReferralRedirect';

import './styles.css';
import { createRoot } from 'react-dom/client';

const GameWrapper = React.lazy(() => import('./GameWrapper'));

const Index = () => {
  const set = useStore((state) => state.set);
  const stateUser = useStore((state) => state.user);
  const isPresenting = useStore((state) => state.settings.isPresenting);
  const dailyLevel = useStore((state) => state.settings.dailyLevel);

  auth.onAuthStateChanged(async (user) => {
    if (user && !stateUser.id) {
      const id = user.uid;
      const loggedInAt = new Date();
      let sessions = 1;

      const docRef = firestore.collection('players').doc(id);
      const doc = await docRef.get();

      let progress = [];
      let rating;

      if (doc.exists) {
        const data = doc.data();
        ({ sessions, progress, rating } = data!);

        await docRef.update({
          lastLoggedInAt: new Date(),
          sessions: fb.firestore.FieldValue.increment(1),
        });

        sessions++;
      } else {
        await docRef.set({
          id: id,
          lastLoggedInAt: new Date(),
          registeredAt: loggedInAt,
          sessions: sessions,
          progress: [],
        });
      }
      set((state) => {
        state.user = { ...state.user, id, loggedInAt, sessions, progress, rating };
        return state;
      });
    }
  });

  // Only users who play the game get a anonymous user.
  useEffect(() => {
    if (isPresenting) {
      (async () => {
        try {
          await auth.signInAnonymously();
        } catch (ex) {
          console.log(ex);
        }
      })();
    }
  }, [isPresenting]);

  useEffect(() => {
    void (async () => {
      let correctVersion = true;
      const docRef = firestore.collection('game').doc('settings');
      const document = await docRef.get();
      const { versions, dailyLevel, randomLevels = [] } = document.data();

      if (process.env.COMMIT_REF && !versions.includes(`${process.env.COMMIT_REF}`)) {
        correctVersion = false;
      }

      set((state) => {
        state.settings.isCorrectVersion = correctVersion;
        state.settings.dailyLevel = dailyLevel;
        state.settings.randomLevels = randomLevels;
        return state;
      });
    })();
  }, []);

  useEffect(() => {
    // TODO: this won't run if there is no dailyLevel from the API
    // so make sure we always set this
    // we could have a fallback where we store the api levels
    // and check that array
    if (dailyLevel) {
      void (async () => {
        const docRef = firestore.collection('levels');
        const levels = await docRef.get();

        // TODO: fix loading all levels here... causes to many reads
        set((state) => {
          levels.docs.forEach((doc) => {
            const apiLevel = doc.data();

            const averageTime = apiLevel.averageTime;
            const bestTime = apiLevel.bestTime;
            const totalTime = apiLevel.totalTime;

            // we add these only when there is no state level
            // these come from the API

            let importedLevel = {};

            if (apiLevel.blueprint) {
              importedLevel = JSON.parse(apiLevel.blueprint);
            }

            state.levels[apiLevel.id] = {
              ...state.levels[apiLevel.id],
              id: apiLevel.id,
              averageTime,
              bestTime,
              totalTime,
              name: apiLevel.name,
              hint: apiLevel.hint,
              // finishedAt: undefined? this should be handled by state
              // no time information
              ...importedLevel,
            } as any;
          });
        });
      })();
    }
  }, []);

  return (
    <div className="bg-sherpa-blue-500 relative">
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/play" element={<GameWrapper />} />
            <Route path="/" element={<App />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

const container = document.getElementById('root');

const root = createRoot(container);
root.render(<Index />);

// ReactDOM.render(<Index />, document.getElementById('root'));
