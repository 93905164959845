import React, { ReactElement, useEffect, useRef, useState } from 'react';
import browserVideo from 'url:../assets/plocklebrowser.webm';
import playingDemo from 'url:../assets/plockledemos.webm';
import resetPlayPositionVideo from 'url:../assets/plockledemo2s.webm';
import plocklePlayedByPeople from 'url:../assets/plocklepeopleplaying.webm';
// import plocklePlayedByPeople from '../assets/plockledemosnew.mp4';

import plockleDemo1 from 'url:../assets/plockledemo1.jpg';
import plockleDemo2 from 'url:../assets/plockledemo2.jpg';
import plockleDemo3 from 'url:../assets/plockledemo3.jpg';
import plockleDemo4 from 'url:../assets/plockledemo4.jpg';

import MouseIcon from './icons/MouseIcon';

import useIsInViewport from 'use-is-in-viewport';
import { useWindowSize } from '../../utils/useWindowSize';
import PlayIcon from './icons/PlayIcon';

const showcase = [
  {
    title: '40 Levels (for now)',
    description: 'Atleast 10 hours of gameplay while more levels are being created!',
    color: '#ff003c',
    video: playingDemo,
    image: plockleDemo1,
  },
  {
    title: 'In the browser',
    description: 'No download required. Just click play and put on your VR headset.',
    color: '#ff8a00',
    video: browserVideo,
    image: plockleDemo2,
  },
  {
    title: 'For all ages, for free',
    description: 'Plockle is a free to play game that can be enjoyed by anyone at any age.',
    color: '#fabe28',
    video: plocklePlayedByPeople,
    image: plockleDemo3,
  },
  {
    title: 'Reset playing position',
    description: 'Tired of standing? Reset your play position in game and sit back.',
    color: '#88c100',
    video: resetPlayPositionVideo,
    image: plockleDemo4,
  },
];

export default function Showcase(): ReactElement {
  const [width] = useWindowSize();
  const refs = showcase.map((_) => useRef(null));
  const [hoveringOverBlockAnimation, setHoveringOverBlockAnimation] = useState(showcase.map((_) => false));
  const [hoveringOver, setHoveringOver] = useState(showcase.map((_) => false));
  const [loaded, setLoaded] = useState(false);

  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });

  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport]);

  useEffect(() => {
    setHoveringOverBlockAnimation((state) => {
      state = showcase.map((_) => false);
      state[0] = true;
      return state;
    });
  }, []);

  const INTERVAL_DELAY = 1500;
  useEffect(() => {
    const interval = setInterval(() => {
      let index = hoveringOverBlockAnimation.indexOf(true);
      index++;
      if (index === hoveringOverBlockAnimation.length) {
        index = 0;
      }

      setHoveringOverBlockAnimation((state) => {
        state = showcase.map((_) => false);
        state[index] = true;
        return state;
      });
    }, INTERVAL_DELAY);
    return () => clearInterval(interval);
  });

  return (
    <div ref={targetRef} className="md:max-w-6xl md:mx-auto mt-32">
      <div className="grid gap-6 md:gap-6 grid-cols-1 md:grid-cols-2 relative z-10">
        {showcase.map(({ title, description, color, video, image }, index) => (
          <div
            className={`flex flex-row rounded-lg overflow-hidden shadow duration-700 delay-150 relative transform transition-all ease-out ${
              !loaded && !(width < 768) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'
            }`}
            style={{ background: color }}
            key={index}
            onMouseOver={(event) => {
              refs[index].current?.play();
              setHoveringOverBlockAnimation((state) => {
                state[index] = true;
                return [...state];
              });
              setHoveringOver((state) => {
                state[index] = true;
                return [...state];
              });
            }}
            onMouseOut={(event) => {
              refs[index].current?.pause();
              setHoveringOverBlockAnimation((state) => {
                state[index] = false;
                return [...state];
              });
              setHoveringOver((state) => {
                state[index] = false;
                return [...state];
              });
            }}
          >
            <div className="flex-none w-32 sm:w-32 relative flex">
              <video
                ref={refs[index]}
                src={video}
                loop
                controls={false}
                muted
                className="object-cover w-full"
                style={{ backgroundImage: image }}
              />
              {!hoveringOver[index] && (
                // <img src={`${process.env.baseUrl}/assets/images/.jpg`} alt="" className="absolute w-full h-full object-cover" />
                <>
                  <img src={image} alt="" className="absolute w-full h-full object-cover" />
                  {width < 768 && (
                    <div className="absolute flex items-center justify-center w-full h-full">
                      <PlayIcon className="w-10 h-10" style={{ fill: color }} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-wrap p-6 relative">
              <h1 className="flex-auto text-2xl font-bold text-white">{title}</h1>
              <div className="w-full flex-none text-xl text-white mt-2">{description}</div>
              {!(width <= 768) && (
                <MouseIcon
                  className={`absolute right-0 bottom-0 block mr-4 mb-4 w-5 h-5 ${
                    !hoveringOver[index] && hoveringOverBlockAnimation[index] ? 'animate-mouseover' : ''
                  } opacity-0`}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
