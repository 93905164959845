import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSmoothScrollTo = (id: string) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const listener = (e) => {
      if (ref.current && location.hash === id) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/');
      }
    };
    window.addEventListener('hashchange', listener, true);
    return () => {
      window.removeEventListener('hashchange', listener);
    };
  }, []);
  return {
    'data-anchor-id': id,
    ref,
  };
};
