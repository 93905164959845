import React, { ReactElement, useEffect, useRef, useState } from 'react'
import useIsInViewport from 'use-is-in-viewport';
import { firestore } from '../../firebase';
import { useStore } from '../../store';
import { useSmoothScrollTo } from '../../utils/useSmoothScrollTo';
import { useWindowSize } from '../../utils/useWindowSize';

export default function Contact(): ReactElement {

  const [width] = useWindowSize();
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 })
  const [loaded, setLoaded] = useState(false);

  const emailRef = useRef<HTMLInputElement | null>(null);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  
  const userId = useStore(state => state.user?.id);

  const bind = useSmoothScrollTo('#contact')

  const [formMessage, setFormMessage] = useState<string | null>(null);

  const submitForm = async () => {
    if (!emailRef.current?.value) {
      return setFormMessage('Please provide an email!');
    }

    const regex = /\S+@\S+\.\S+/;

    if (!regex.test(emailRef.current?.value)) {
      return setFormMessage('Please provide a valid email!');
    }

    if (!messageRef.current?.value) {
      return setFormMessage('Please provide a message!');
    }

    if ((messageRef.current?.value.length === 0)) {
      return setFormMessage('Please provide a message!');
    }

    const docRef = firestore.collection("messages");

    await docRef.add({
      ...(userId ? {userId} : {}),
      createdAt: new Date(),
      email: emailRef.current.value,
      message: messageRef.current.value,
    });

    emailRef.current.value = '';
    messageRef.current.value = '';

    setFormMessage('Message has been sent!');
  }

  
  useEffect(() => {
    if (!loaded && isInViewport) {
      setLoaded(true);
    }
  }, [isInViewport])
  return (
    <div className={`md:max-w-6xl md:mx-auto mt-16 md:mt-32 relative`} {...bind}>
      <div className={`duration-700 delay-150 transform transition-all ease-out ${(!loaded && !(width < 768)) ? 'opacity-0 translate-y-12' : 'opacity-100 translate-y-0'}`} ref={targetRef} >

            <h1 className="font-bold text-white text-4xl md:text-5xl leading-tight">
              Contact
            </h1>
            <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">
              Any questions?
            </p>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <div className="h-full flex flex-col justify-between md:pr-8">
                  <div className="text-sherpa-blue-200">
                    <p>Want to <strong>send me some feedback</strong>? A review? Or just want to <strong>contact me</strong>? Please use the <strong>contact form</strong> or contact Plockle on the <strong>socials</strong> mentioned below.</p>
                    <p>Please <strong>reach out</strong> if your facing <strong>technical difficulties</strong>. As mentioned above, the game is still in active development so bugs might occur. </p>
                  </div>
                  <div>
                    <p className="font-bold text-sherpa-blue-100 text-2xl md:text-2xl leading-tight">Go follow Plockle</p>
                    <div className="flex flex-wrap items-start">
                      <a className="font-bold rounded-lg py-3 px-8 text-white mt-4 mr-4" href="https://discord.gg/ShNgMD5pr8" style={{ background: '#7289DA' }} target="_blank" rel="noopener noreferrer">Discord</a>
                      <a className="font-bold rounded-lg py-3 px-8 bg-emerald-500 text-white mt-4 mr-4" href="https://instagram.com/plockleVR" style={{ background: '#C13584' }} target="_blank" rel="noopener noreferrer">Instagram</a>
                      <a className="font-bold rounded-lg py-3 px-8 bg-emerald-500 text-white mt-4 mr-4" href="https://www.facebook.com/Plockle-535608560691560" style={{ background: '#4267B2' }} target="_blank" rel="noopener noreferrer">Facebook</a>
                      <a className="font-bold rounded-lg py-3 px-8 bg-emerald-500 text-white mt-4 mr-4" href="https://twitter.com/plockleVR" style={{ background: '#1DA1F2' }} target="_blank" rel="noopener noreferrer">Twitter</a>
                      <a className="font-bold rounded-lg py-3 px-8 bg-emerald-500 text-white mt-4 mr-4" href="https://www.reddit.com/r/plockle/" style={{ background: '#FF5700' }} target="_blank" rel="noopener noreferrer">Reddit</a>
                      <a className="font-bold rounded-lg py-3 px-8 bg-emerald-500 text-white mt-4 mr-4" href="mailto:hi@plockle.com" style={{ background: '#BB001B' }}>Email</a>
                    </div>
                  </div>
                </div>
              </div>
              <form className="w-full md:w-1/2">
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-white" htmlFor="email">
                      Email
                      <input ref={emailRef} className="my-2 appearance-none block w-full bg-sherpa-blue-400 text-white border border-sherpa-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-sherpa-blue-400 focus:border-emerald-500 placeholder-gray-50::placeholder" id="email" type="email" placeholder="puzzlemaster69@gmail.com" />
                    </label>

                  </div>
                </div>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <label className="block tracking-wide text-white">
                      Message
                      <textarea
                        className="my-2 no-resize appearance-none block w-full bg-sherpa-blue-400 text-white border border-sherpa-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-sherpa-blue-400 focus:border-emerald-500 h-48 placeholder-gray-50::placeholder"
                        ref={messageRef}
                        id="message"
                        defaultValue=""
                        placeholder="Loved your game!!"
                        />
                    </label>
                  </div>
                </div>
                <div className="flex items-center">
                    <button type="button" onClick={submitForm} className="font-bold focus:outline-none inline-block py-3 px-8 text-white bg-emerald-500 hover:bg-emerald-600 rounded-lg shadow">Send</button>
                  {formMessage && (
                    <span className="ml-2">{formMessage}</span>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
  )
}
