import React, { ReactElement, ReactNode, useLayoutEffect, useState } from 'react';
import { Suspense } from 'react';

import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Showcase from './components/Showcase';
import Support from './components/Support';
import Tutorial from './components/Tutorial';

// import landing from 'url:./assets/landing.webm';

const Show = React.lazy(() => import('./components/Show'));

export default function App(): ReactElement {
  return (
    <>
      <Suspense fallback={null}>
        <div className="absolute w-full h-screen z-1">
          <Show />
        </div>
      </Suspense>
      <div className="antialiased w-full text-sherpa-blue-200 bg-sherpa-blue-500 px-4">
        <Header />
        <div className="text-xl content">
          <About />
          <Showcase />
          <Tutorial />
          {/* <Referral /> */}
          {/* <Support /> */}
          <Contact />
        </div>
        <Footer />
      </div>
    </>
  );
}
